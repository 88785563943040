.text-overlay-shadow {
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

body, svg {
  fill: currentColor;
}

svg path {
  stroke: currentColor;
}

.icon-label {
  display: inline-block;
  transform: translate(0, 0.15em);
}
