.alert-info {
  color: #08616C;
  border: 0.6px solid #01C6D2;
  background-color: rgba(1, 198, 210, 0.04) !important;
  font-size: 13px;
}

.alert-warning {
  color: #FF7A00;
  background: rgba(255, 122, 0, 0.04);
  border: 0.6px solid #FF7A00;
}
