$input-btn-font-family: "Roboto Flex", Arial, sans-serif;
$input-btn-line-height: 1.875;
$input-btn-font-size: 1rem;
$input-btn-padding-y: 0;
$input-btn-padding-x: 1rem;

$input-btn-font-size-sm: .8rem;
$input-btn-padding-y-sm: 0;
$input-btn-padding-x-sm: .5rem;

$input-btn-padding-y-lg: .75rem;
$input-btn-padding-x-lg: 1.5rem;

$badge-padding-y: .35rem;
$badge-pill-padding-x: 1rem;
$badge-font-weight: 400;

$font-weight-normal: 300;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$btn-font-weight: 600;

$headings-font-family: "Roboto Flex", Arial, sans-serif;
$headings-font-weight: 300;

$font-family-base: "Roboto Flex", Arial, sans-serif;
$input-font-family: "Roboto Flex", Arial, sans-serif;

$border-color: #e5e5e5;

$grid-breakpoints: (
  xs: 0,
  sm: 577px,
  md: 769px,
  lg: 993px,
  xl: 1201px
) !default;

$container-max-widths: (
  sm: 577px,
  md: 769px,
  lg: 993px,
  xl: 1201px
) !default;

$danger: #EA6864;
$dark: #000;
$light: #fff;
$info: #01C6D2;
$warning: #FF7A00;

$form-feedback-invalid-color: $warning;

$modal-header-padding-x: 1.5rem;
$modal-header-padding-y: .75rem;
$modal-footer-border-width: 0;
$modal-scale-transform: 1;

$spacer: 1rem !default;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

$grid-gutter-width: 16px;

$alert-border-width: 0px;
$alert-bg-level: 0;
$alert-color-level: -12;

$h1-font-size: 2rem;

// Default z-index settings, copied here because all these z-indexes are designed to work together.
$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
/**
 * These elements need to be higher than the tawk.io widget, which has inline,!important z-index of
 * between 1000000000-2000000000 depending on unknown conditions.
 */
$zindex-popover:                    2000000030;
$zindex-tooltip:                    2000000031;
$zindex-modal-backdrop:             2000000040;
$zindex-modal:                      2000000050;
