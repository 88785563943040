.modal-title {
  font-size: 1.125rem;
  line-height: 1.7;
  font-weight: 400;
}
.modal-header .close {
  border: 0;
  padding: 0 0 0 .5rem;
  margin: 0 0 0 auto;
  line-height: 1;
  font-size: 2rem;
}
.modal-header,
.modal-content,
.modal-footer {
  border: 0;
  border-radius: 0;
}

.modal-content {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.65);
}

.modal-body,
.modal-footer {
  padding: 1.5rem;
}
