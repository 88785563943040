.custom-select.sub-nav-select {
  padding: 0.6rem 1.75rem 0.6rem 1rem !important;
  height: calc(1.5em + 1rem + 2px) !important;
  border-radius: 2px !important;
  font-weight: 600;
  line-height: 1.5;
}

.form-control-focus .form-control {
  @include form-control-focus();
}
