.active-shadow:hover,
.active-shadow:focus {
  outline: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  transition: box-shadow 300ms;
}

.active-after-shadow:after:hover,
.active-after-shadow:after:focus {
  @extend .active-shadow;
}

.rounded .active-after-shadow:after,
.rounded .active-after-shadow:after {
  border-radius: $border-radius;
}
